<template>
  <!-- Page Content -->
  <div class="mx-10 my-2">
    <div v-if="isEditMode">
      <h2 class="my-4 text-4xl font-semibold">Edit Agent</h2>
      <div
        class="pb-2 flex items-center justify-between text-gray-600 border-b"
      >
        <p>Edit Agent</p>
      </div>
    </div>

    <div v-else>
      <h2 class="my-4 text-4xl font-semibold">Add Agent</h2>
      <div
        class="pb-2 flex items-center justify-between text-gray-600 border-b"
      >
        <p>Add an agent to the database</p>
      </div>
    </div>
    <!-- Agent Content -->
    <div class="flex flex-wrap">
      <div class="w-full lg:w-1/3 mt-5">
        <label for="admin-agent-name" class="w-full text-sm font-semibold"
          >Name</label
        >
        <input
          v-model="form.firstName"
          id="admin-agent-name"
          class="lb-admin-form-input rounded"
          placeholder="First name"
          type="text"
        />
      </div>
      <div class="w-full lg:w-1/3 mt-5">
        <label for="admin-agent-surname" class="w-full text-sm font-semibold"
          >Last name</label
        >
        <input
          v-model="form.lastName"
          id="admin-agent-surname"
          class="lb-admin-form-input rounded"
          placeholder="Last name"
          type="text"
        />
      </div>
      <div class="w-full lg:w-1/3 mt-5">
        <label for="admin-agent-email" class="w-full text-sm font-semibold"
          >Email</label
        >
        <input
          v-model="form.email"
          id="admin-agent-email"
          class="lb-admin-form-input rounded"
          placeholder="Email address"
          type="text"
        />
      </div>
      <div class="w-full lg:w-1/3 mt-5">
        <label for="admin-agent-phone" class="w-full text-sm font-semibold"
          >Phone number</label
        >
        <input
          v-model="form.contact"
          id="admin-agent-phone"
          class="lb-admin-form-input rounded"
          placeholder="Phone number"
          type="text"
        />
      </div>
      <div class="w-full lg:w-1/3 mt-5">
        <label for="admin-agent-order" class="w-full text-sm font-semibold"
          >Order</label
        >
        <input
          v-model="form.order"
          id="admin-agent-order"
          class="lb-admin-form-input rounded"
          placeholder="Order by number"
          type="number"
        />
      </div>
    </div>

    <!-- Page Content Btn -->
    <div class="w-full mt-10 pt-5 border-t">
      <button
        v-if="!isEditMode"
        @click.prevent="addAgent"
        class="bg-primary px-4 py-2 text-white font-semibold rounded"
      >
        Add Agent
      </button>

      <button
        v-if="isEditMode"
        @click.prevent="updateAgent(agentID)"
        class="bg-primary px-4 py-2 text-white font-semibold rounded"
      >
        Update Agent
      </button>

      <button
        v-if="isEditMode && hasAccess('agents', 'delete')"
        @click.prevent="deleteAgent(agentID)"
        class="bg-red-600 ml-5 px-4 py-2 text-white font-semibold rounded"
      >
        Delete Agent
      </button>
    </div>
  </div>
</template>

<script>
import firebase from "firebase";
import {profilesCollection} from "@/firebase.js";
import permissionsMixin from "@/mixins/permissions";

import isNil from "lodash/isNil";

export default {
  name: "AdminAgent",
  mixins: [permissionsMixin],
  data: () => ({
    agentID: null,
    form: {
      firstName: null,
      lastName: null,
      email: null,
      contact: null,
      order: null,
    },
  }),
  methods: {
    getAgent(agentID) {
      profilesCollection
        .doc(agentID)
        .get()
        .then((doc) => {
          this.form.firstName = doc.data().firstName;
          this.form.lastName = doc.data().lastName;
          this.form.email = doc.data().email;
          this.form.contact = doc.data().formatNationalContactNumber;
          this.form.order = doc.data().order;

          this.agentID = doc.id;
        });
    },
    addAgent() {
      const agentData = {
        firstName: this.form.firstName || null,
        lastName: this.form.lastName || null,
        email: this.form.email || null,
        contact: this.form.formatNationalContactNumber || null,
        order: this.form.order || null,
        createdAt: firebase.firestore.Timestamp.now(),
      };
      profilesCollection
        .add(agentData)
        .then(() => {
          this.$notify({
            group: "global",
            type: "success",
            title: "Agent Added",
          });
          this.$router.replace({ name: "admin-agents" });
        })
        .catch((error) => {
          this.$notify({
            group: "global",
            type: "error",
            title: "Error adding Agent",
            text: error.message,
          });
          this.$router.replace({ name: "admin-agents" });
        });
    },
    updateAgent(agentID) {
      const agentData = {
        firstName: this.form.firstName || null,
        lastName: this.form.lastName || null,
        email: this.form.email || null,
        formatNationalContactNumber: this.form.contact || null,
        order: this.form.order || null,
      };
      profilesCollection
        .doc(agentID)
        .update(agentData)
        .then(() => {
          this.$notify({
            group: "global",
            type: "success",
            title: "Agent Updated",
          });
          this.$router.replace({ name: "admin-agents" });
        })
        .catch((error) => {
          this.$notify({
            group: "global",
            type: "error",
            title: "Error updating Agent",
            text: error.message,
          });
          this.$router.replace({ name: "admin-agents" });
        });
    },
    deleteAgent(agentID) {
      var userConfirmedDelete = confirm(
        "Are you sure you want to delete this agent?"
      );
      if (userConfirmedDelete) {
        profilesCollection
          .doc(agentID)
          .delete()
          .then(() => {
            this.$notify({
              group: "global",
              type: "success",
              title: "Agent Deleted",
            });

            this.$router.replace({ name: "admin-agents" });
          })
          .catch((error) => {
            this.$notify({
              group: "global",
              type: "error",
              title: error,
            });
          });
      }
    },
  },
  computed: {
    isEditMode() {
      if (!isNil(this.$route.params.id)) {
        return true;
      }
      return false;
    },
  },
  mounted() {
    if (!isNil(this.$route.params.id)) {
      const agentID = this.$route.params.id;
      this.getAgent(agentID);
    }
  },
};
</script>
